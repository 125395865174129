import React from "react"
import { useStaticQuery, Link, graphql } from "gatsby"
import {
  localizedMenuItems,
  footerMenuItems,
  socialMenuItems,
} from "../constants/menu-items"
import styled from "styled-components"
import PropTypes from "prop-types"
import { getImage, GatsbyImage } from "gatsby-plugin-image"
import { getCurrentLocale } from "../utils/getCurrentLocale"

const Footer = ({ Logo }) => {
  const data = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            author
            authorSite
          }
        }
        herczegagnes: file(relativePath: { eq: "images/herczegagnes.png" }) {
          childImageSharp {
            gatsbyImageData(
              width: 200
              placeholder: BLURRED
              formats: [AUTO, WEBP, AVIF]
            )
          }
        }
        winesOfHungary: file(relativePath: { eq: "images/wines_of_hungary.png" }) {
          childImageSharp {
            gatsbyImageData(
              width: 200
              placeholder: BLURRED
              formats: [AUTO, WEBP, AVIF]
            )
          }
        }
        wim: file(relativePath: { eq: "images/logo_wim.png" }) {
          childImageSharp {
            gatsbyImageData(
              width: 200
              placeholder: BLURRED
              formats: [AUTO, WEBP, AVIF]
            )
          }
        }
      }
    `
  )

  const footerData = data.site.siteMetadata
  const locale = getCurrentLocale()
  const herczegagnes = getImage(data.herczegagnes)
  const winesOfHungary = getImage(data.winesOfHungary)
  const wim = getImage(data.wim)

  return (
    <FooterStyles id="contact">
      <div className="flex">
        {Logo ? (
          <div className="brand-cont">
            {/* If there is a logo, render this */}
            {Logo && (
              <Link to={`/${locale}`}>
                <img src={Logo} alt={footerData.title} />
              </Link>
            )}
            <address>
              Wine Treasury Kft.
              <br />
              Ibrahim utca 26. 706.
              <br />
              1116 Budapest,
              <br />
              Hungary
            </address>
            <a 
              className="telephone"
              href="mailto:info@winawegierskie.com"
              rel="noopener noreferrer"
            >
              info@winawegierskie.com
            </a>
          </div>
        ) : null}

        <div className="logos">
          <a className="logo" href="https://herczegagnes.hu" target="__blank">
            <GatsbyImage image={herczegagnes} alt={"Herczeg Ágnes Wine Consulting"}/>
          </a>
          
          <a className="logo" href="https://winesofhungary.hu" target="__blank">
            <GatsbyImage image={winesOfHungary} alt={"Wines Of Hungary"}/>
          </a>

          <a className="logo" href="https://www.wineinmoderation.eu/" target="__blank">
            <GatsbyImage image={wim} alt={"Wine In Moderation"}/>
          </a>
        </div>

        {localizedMenuItems || socialMenuItems || footerMenuItems ? (
          <div className="menus-cont">
            {/* If main menu items are being imported, render this */}
            {localizedMenuItems && (
              <ul className="footer-menu">
                {/* First we want to filter out the Home menu item, then display the rest of them */}
                {localizedMenuItems
                  .filter(item => item.en.title !== "home")
                  .filter(item => item.mainMenu)
                  .map((item, index) => (
                    <li key={`menuItem${index}`}>
                      <Link to={item[locale].path}>{item[locale].title}</Link>
                    </li>
                  ))}
              </ul>
            )}

            {/* If social menu items are being imported, render this */}
            {socialMenuItems && (
              <ul className="footer-menu">
                {socialMenuItems.map((item, index) => {
                  return (
                    <li key={index}>
                      <a
                        href={item.url}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        {item.name}
                      </a>
                    </li>
                  )
                })}
              </ul>
            )}

            {/* If footer menu items are being imported, render this */}
            {(footerMenuItems?.length ?? 0) !== 0 && (
              <ul className="footer-menu">
                {footerMenuItems.map((item, index) => (
                  <li key={`footerMenuItem${index}`}>
                    <Link to={item.path}>{item.title}</Link>
                  </li>
                ))}
              </ul>
            )}
          </div>
        ) : null}
      </div>
      <div className="copy-cont">
        <ul className="copy">
          <li>&copy; {new Date().getFullYear()}</li>

          {/* if there is an author stated in the config, render this */}
          {footerData.author && (
            <li>
              <a
                href={footerData.authorSite}
                target="_blank"
                rel="noopener noreferrer"
              >
                {footerData.author}
              </a>
            </li>
          )}
        </ul>
      </div>
    </FooterStyles>
  )
}

Footer.propTypes = {
  Logo: PropTypes.string,
}

const FooterStyles = styled.footer`
  padding: calc(var(--spacing) * 2);
  background-color: #f9f9f9;
  font-family: var(--serif);
  font-weight: 300;
  font-style: normal;
  color: var(--charcoal);

  .flex {
    width: 100%;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;

    @media (min-width: 768px) {
      flex-direction: row;
      justify-content: space-between;
    }
  }

  .logos {
    display: flex;
    flex-direction: column;
  }

  .logo {
    width: 200px;
    margin: 10px;
  }

  .telephone,
  address,
  li {
    font-size: var(--footerMenuItem);
  }

  address {
    font-style: normal;
    margin-bottom: var(--spacing);
  }

  li {
    text-transform: capitalize;
    list-style: none;
    padding-left: 0;
    margin-top: 5px;
    margin-bottom: 5px;

    @media (min-width: 768px) {
      margin-top: 10px;
      margin-bottom: 10px;
    }
  }

  a {
    text-decoration: none;
    color: var(--charcoal);
    transition: var(--transMed);

    &:hover {
      color: var(--primaryColor);
    }
  }

  @media (min-width: 768px) {
    padding-top: calc(var(--spacing) * 4);
    padding-bottom: calc(var(--spacing) * 4);
  }

  .brand-cont,
  .brand-cont img {
    margin-bottom: calc(var(--spacing) * 2);
  }

  .brand-cont {
    margin-left: 0;
    width: 100%;

    a {
      display: inline-block;
    }

    img {
      width: 100%;
    }
  }

  .footer-menu {
    margin: 0;
    margin-right: 10px;
    width: 50%;
    flex-shrink: 0;
    margin-bottom: calc(var(--spacing) * 2);
    padding: 0;
    margin-bottom: calc(var(--spacing) * 2);

    &:last-child {
      margin-bottom: 0;
    }

    @media (min-width: 375px) {
      margin-bottom: 0;
    }

    @media (min-width: 768px) {
      flex-grow: 0;
      margin-bottom: 0;

      li {
        padding-left: calc(var(--spacing) / 2);
      }
    }
  }

  .brand-cont,
  .menus-cont,
  .copy-cont {
    font-size: 0.85rem;
  }

  .brand-cont {
    width: 125px;

    @media (min-width: 600px) {
      width: 150px;
      flex-basis: 150px;
      flex-shrink: 0;
      flex-grow: 0;
    }
  }

  .menus-cont {
    display: flex;
    margin-top: 15px;
    flex-direction: column;

    @media (min-width: 768px) {
      max-width: 300px;
      margin-top: 0;
      flex-direction: row;
    }
  }

  .copy {
    margin: 0;
    padding: 0;
    margin-top: calc(var(--spacing) * 2);

    @media (min-width: 600px) {
      margin-top: 0;
    }

    li {
      display: inline;
      margin-right: var(--spacing);
    }
  }
`

export default Footer
