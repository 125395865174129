import { graphql, useStaticQuery } from 'gatsby'
import { getImage } from "gatsby-plugin-image"
import React from "react"
import Header from "./Header"
import Footer from "./Footer"
import GlobalStyles from "../styles/GlobalStyles"
import Typography from "../styles/Typography"
import Logo from "../content/images/logo.png"

import { convertToBgImage } from "gbimage-bridge"
import BackgroundImage from 'gatsby-background-image'
import { LightgalleryProvider } from "react-lightgallery";
import "lightgallery.js/dist/css/lightgallery.css";

const Layout = ({ children }) => {
  const { placeholderImage } = useStaticQuery(
    graphql`
      query {
        placeholderImage: file(relativePath: { eq: "images/background.png" }) {
          childImageSharp {
            gatsbyImageData(
              width: 200
              placeholder: BLURRED
              formats: [AUTO, WEBP, AVIF]
            )
          }
        }
      }
    `
  )
  const image = getImage(placeholderImage)
  const bgImage = convertToBgImage(image)
  return (
    <BackgroundImage
      Tag="section"
      {...bgImage}
      preserveStackingContext
      style={{
        backgroundPosition: "top left",
        backgroundRepeat: "no-repeat",
        backgroundSize: "contain",
      }}
    >
      <LightgalleryProvider>
        <GlobalStyles />
        <Typography />
        <Header Logo={Logo} />
        <main className="main-body">{children}</main>
        <Footer Logo={Logo} />
      </LightgalleryProvider>
    </BackgroundImage>
  )
}

export default Layout
