import React, { useState } from "react"
import { getCurrentLocale } from "../utils/getCurrentLocale"
import { Link } from "gatsby"

const MenuItem = ({ onClick, mainMenu, subMenus }) => {
  const [isOpen, setIsOpen] = useState(false);
  const locale = getCurrentLocale();

  return (
    <li
      key={mainMenu[locale].title}
      className="nav-link"
      onClick={onClick}
      onMouseEnter={() => setIsOpen(true)}
      onMouseLeave={() => setIsOpen(false)}
    >
      <Link to={mainMenu[locale].path}>
        {mainMenu[locale].title}
      </Link>
      {isOpen && (subMenus ?? []).length !== 0
        ? <div className="nav-submenu">
          { subMenus
            .map(subMenu => <Link className="submenu-item" to={subMenu[locale].path}>{subMenu[locale].title}</Link>)}
        </div>
        : <></>
      }
    </li>
  );
};

export default MenuItem;