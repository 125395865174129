import { useStaticQuery, graphql } from "gatsby"
import React, { useContext } from "react"
import { localizedMenuItems, socialMenuItems } from "../constants/menu-items"
import { getImage, GatsbyImage } from "gatsby-plugin-image"
import { NavigationStyles } from "../styles/NavStyles"
import { getCurrentLocale } from "../utils/getCurrentLocale"
import MenuContext from "./MenuContext"
import MenuItem from './MenuItem'
import { getCurrentPath } from "../utils/getCurrentPath"
import { getOtherLocale } from "../utils/getOtherLocale"

const Navigation = () => {
  const [isOpen, setNav] = useContext(MenuContext)
  const data = useStaticQuery(graphql`
    query Flags {
      polish: file(relativePath: { eq: "images/polish.png" }) {
        childImageSharp {
          gatsbyImageData(
            width: 18
            height: 18
            placeholder: BLURRED
            formats: [AUTO, WEBP, AVIF]
          )
        }
      }
      english: file(relativePath: { eq: "images/english.png" }) {
        childImageSharp {
          gatsbyImageData(
            width: 18
            height: 18
            placeholder: BLURRED
            formats: [AUTO, WEBP, AVIF]
          )
        }
      }
    }
  `)
  const polish = getImage(data.polish)
  const english = getImage(data.english)
  const locale = getCurrentLocale();
  const currentUrl = getCurrentPath();

  const toggleNav = () => {
    setNav(isOpen => !isOpen)
  }

  const getCurrentMenuItem = () => {
    return localizedMenuItems.find(menuItem => 
      currentUrl.endsWith(menuItem.en.path) || currentUrl.endsWith(menuItem.pl.path)
    )
  }

  return (
    <NavigationStyles className={isOpen ? "open" : "closed"}>
      <ul>
        {localizedMenuItems
          .filter(item => item.mainMenu)
          .map(mainMenu => {
            const mainMenuCurrentPath = mainMenu[locale].path
            const amountOfSlashes = mainMenuCurrentPath.split("/").length;
      
            return {
              mainMenu,
              subMenus: localizedMenuItems
                .filter(menu => menu[locale].path.startsWith(mainMenuCurrentPath) 
                  && menu[locale].path.split("/").length - 1 ===amountOfSlashes)
            }
          })
          .map(item => (
            <MenuItem 
              mainMenu={item.mainMenu}
              subMenus={item.subMenus}
              onClick={toggleNav}
              key={item.mainMenu[locale].title}
            />
        ))}
        <li key={`social-icons`} className="nav-link">
          Follow us!
          {socialMenuItems.map((item, index) =>
            <a
              key={index}
              href={item.url}
              target="_blank"
              rel="noopener noreferrer"
            >
              {item.icon}
            </a>
          )}
          {
            getCurrentMenuItem()
              ? <a href={getCurrentMenuItem()[getOtherLocale()].path}>
                  {
                    locale === "en"
                      ? <GatsbyImage image={polish} alt={"Polish"}/>
                      : <GatsbyImage image={english} alt={"English"}/>
                  }
                </a>
              : <></>
          }
          
        </li>
      </ul>
    </NavigationStyles>
  )
}

export default Navigation
