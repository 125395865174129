import React from "react"
import Layout from "./src/components/Layout"
import { MenuProvider } from "./src/components/MenuContext"
import { defaultLangKey } from './src/data/languages';

export function wrapPageElement({ element, props }) {
  return <Layout {...props}>{element}</Layout>
}

export function wrapRootElement({ element }) {
  return <MenuProvider>{element}</MenuProvider>
}

export function onClientEntry() {
  if (window.location.pathname === '/') {
    window.location.pathname = `/${defaultLangKey}`
  }
}