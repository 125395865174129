// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-templates-index-template-js": () => import("./../../../src/templates/index-template.js" /* webpackChunkName: "component---src-templates-index-template-js" */),
  "component---src-templates-post-js": () => import("./../../../src/templates/post.js" /* webpackChunkName: "component---src-templates-post-js" */),
  "component---src-templates-submenu-template-js": () => import("./../../../src/templates/submenu-template.js" /* webpackChunkName: "component---src-templates-submenu-template-js" */),
  "component---src-templates-winery-template-js": () => import("./../../../src/templates/winery-template.js" /* webpackChunkName: "component---src-templates-winery-template-js" */)
}

