import React from "react"
import { FaInstagram, FaFacebook} from "react-icons/fa"
import generatedMenuItems from '../../generated-menu-items.json';

const wineryMenuItems = [
  {
    mainMenu: false,
    en: {
      path: '/en/wineries/csanyi',
      title: 'Csányi Winery',
    },
    pl: {
      path: '/pl/nasze-winiarnie/winnica-csanyi',
      title: 'Winnica Csányi',
    },
  },
  {
    mainMenu: false,
    en: {
      path: '/en/wineries/grand-tokaj',
      title: 'Grand Tokaj Winery',
    },
    pl: {
      path: '/pl/nasze-winiarnie/winnica-grand-tokaj',
      title: 'Winnica Grand Tokaj',
    },
  },
  {
    mainMenu: false,
    en: {
      path: '/en/wineries/hilltop',
      title: 'Hilltop Neszmély Winery',
    },
    pl: {
      path: '/pl/nasze-winiarnie/winnica-hilltop',
      title: 'Winnica Hilltop',
    },
  },
  {
    mainMenu: false,
    en: {
      path: '/en/wineries/kiss-attila',
      title: 'Kiss Attila Winery',
    },
    pl: {
      path: '/pl/nasze-winiarnie/winnica-kiss-attila',
      title: 'Winnica Kiss Attila',
    },
  },
  {
    mainMenu: false,
    en: {
      path: '/en/wineries/etyek',
      title: 'Etyeki Kúria Winery',
    },
    pl: {
      path: '/pl/nasze-winiarnie/etyek',
      title: 'Winiarnia Etyeki Kúria',
    },
  },
  {
    mainMenu: false,
    en: {
      path: '/en/wineries/font',
      title: 'Font Winery',
    },
    pl: {
      path: '/pl/nasze-winiarnie/font',
      title: 'Winiarnia Font',
    },
  },
  {
    mainMenu: false,
    en: {
      path: '/en/wineries/frittman',
      title: 'Frittmann Winery',
    },
    pl: {
      path: '/pl/nasze-winiarnie/frittman',
      title: 'Winiarnia Frittmann',
    },
  },
  {
    mainMenu: false,
    en: {
      path: '/en/wineries/gal',
      title: 'Gál Winery',
    },
    pl: {
      path: '/pl/nasze-winiarnie/gal',
      title: 'Winiarnia Gál',
    },
  },
  {
    mainMenu: false,
    en: {
      path: '/en/wineries/gedeon',
      title: 'Gedeon Estate',
    },
    pl: {
      path: '/pl/nasze-winiarnie/gedeon',
      title: 'Posiadłość Gedeon',
    },
  },
  {
    mainMenu: false,
    en: {
      path: '/en/wineries/hetszolo',
      title: 'Tokaj Hétszőlő Organic Vineyards',
    },
    pl: {
      path: '/pl/nasze-winiarnie/hetszolo',
      title: 'Organiczne winnice Tokaj Hétszőlő',
    },
  },
  {
    mainMenu: false,
    en: {
      path: '/en/wineries/szentpeteri',
      title: 'Szentpéteri Winery',
    },
    pl: {
      path: '/pl/nasze-winiarnie/szentpeteri',
      title: 'Winiarnia Szentpéteri',
    },
  },
  {
    mainMenu: false,
    en: {
      path: '/en/wineries/toth',
      title: 'Tóth Ferenc Winery',
    },
    pl: {
      path: '/pl/nasze-winiarnie/toth',
      title: 'Winiarnia Tóth Ferenc',
    },
  },
  {
    mainMenu: false,
    en: {
      path: '/en/wineries/vida',
      title: 'Vida Péter Szekszárd',
    },
    pl: {
      path: '/pl/nasze-winiarnie/vida',
      title: 'Vida Péter Szekszárd',
    },
  },
]

export const localizedMenuItems = [
  ...wineryMenuItems,
  ...generatedMenuItems,
  {
    mainMenu: false,
    en: {
      path: '/en',
      title: 'Home',
    },
    pl: {
      path: '/pl',
      title: 'Home',
    },
  },
  {
    mainMenu: true,
    en: {
      path: '/en/wineries',
      title: 'Our wineries',
    },
    pl: {
      path: '/pl/nasze-winiarnie',
      title: 'Nasze winiarnie',
    },
  },
  {
    mainMenu: true,
    en: {
      path: '/en/news-events',
      title: 'News & events',
    },
    pl: {
      path: '/pl/wiadomości-i-wydarzenia',
      title: 'Wiadomości i wydarzenia',
    },
  },
  {
    mainMenu: true,
    en: {
      path: '/en/#contact',
      title: 'Contact',
    },
    pl: {
      path: '/pl/#contact',
      title: 'Kontakt',
    },
  }
]

export const mainMenuItems = [
  {
    path: "/",
    title: "home",
  },
  {
    path: "/about",
    title: "about",
  },
  {
    path: "/contact",
    title: "contact",
  },
]

export const socialMenuItems = [
  {
    icon: <FaInstagram />,
    url: "https://www.instagram.com/wina.wegierskie/",
    name: "Instagram",
  },
  {
    icon: <FaFacebook />,
    url: "https://www.facebook.com/winawegierskiePL",
    name: "Facebook",
  },
]

export const footerMenuItems = [
  // {
  //   path: "/privacy",
  //   title: "privacy",
  // },
  // {
  //   path: "/cookies",
  //   title: "cookies",
  // },
]
